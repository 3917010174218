<template>
  <div>
    <b-card>
       
      <section class="row">
        <!-- Skeleton para el filtro -->
        <div class="d-flex col-md-9" v-if="loading.firstRender">
          <b-skeleton height="40px" width="48%" class="mt-2 mb-2" />
          <b-skeleton height="40px" width="18%" class="mt-2 mb-2 ml-2" />
        </div>
        <!--  -->
        <filter-swapper v-show="!loading.firstRender" id="companies_swapper" :controlHeightButtons="controlHeight" class="col-md-9">
          <template #slot1>
            <form-render :key="keyFormRenderCompanies" class="mb-2" :fields="fields" :buttonSend="buttonSend" :form.sync="formFilter"
              @send="simpleFilter(is_mkp)" containerButtonsClass="mt-2">
              <template #buttons>
                <b-button
                  v-if="!show.advancedFilter"
                  variant="outline-light"
                  v-b-tooltip.hover
                  title="Limpiar filtro"
                  class="ml-1"
                  @click="cleanCompaniesFilter"
                  ><feather-icon icon="RefreshCwIcon"
                /></b-button>
                <b-dropdown  v-if="show.advancedFilter" class="ml-2" v-b-tooltip.hover :title="$t('Más opciones')"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="light">
                  <template #button-content><feather-icon icon="SettingsIcon" /></template>
                  <b-dropdown-item @click="cleanCompaniesFilter">{{ $t('Limpiar filtros') }}</b-dropdown-item>
                  <b-dropdown-item @click="openModal(USER_ACTIONS.MODAL_FILTER)">{{ $t('Filtro avanzado') }}</b-dropdown-item>
                </b-dropdown>                
              </template>
            </form-render>          
          </template>
        </filter-swapper>
        <div class="col-3 mt-2" style="float: right">
          <b-button @click="openModal(USER_ACTIONS.MODAL_COMPANIES)" class="push-right" variant="warning">
            {{ $t('Nueva empresa') }}</b-button>
        </div>
      </section>
      <table-render v-show="!loading.table" :key="keyTableRender" id="companies_list" :rows.sync="rows" :schema="schema"
        :actions="actions">
        <template #name2="scope">
          {{ scope.rowdata.name2  ? scope.rowdata.name2 : 'Sin info' }}
        </template>
        <!-- Vista para admin -->
        <template #typeCompany="scope">
          {{ scope.rowdata.marketplace  ? $t('Marketplace') : $t('E-commerce') }}
        </template>
        <!--  -->
        <template #status="scope">
          <b-badge
          :variant="scope.rowdata.activated ? 'light-success' : 'light-secondary'">
            {{ scope.rowdata.activated ? 'Activo' : 'Inactivo' }}
          </b-badge>
        </template>
        <template #deleteCompany="scope">
          <feather-icon icon="TrashIcon" class="delete_icon" @click="confirmDeleteAlert(scope.rowdata.id)"/>
        </template>
      </table-render>
      <pagination v-show="!loading.table" :pagination="pagination" :noDigits="true"/>
      <b-skeleton v-show="loading.table" height="40px" width="100%" class="mt-2 mb-2" />
      <div class="table-render-skeleton" v-show="loading.table">
        <b-skeleton-table :rows="20" :columns="schema.length || 7" :table-props="{}" />
      </div>
    </b-card>
    <modal-filter-avanced @onClose="closeModal" @filter="saveAdvancedFilterData" ref="modalAvancedComp"/>
    <modal-new-company @cleanCompaniesFilter="cleanCompaniesFilter" @onClose="closeModal"/>
  </div>
</template>

<script>
import { environment } from '@/environments/environment'
import { mapGetters } from 'vuex'
import ModalFilterAvanced from './ModalFilterAvanced.vue'
import ModalNewCompany from './ModalNewCompany.vue'

export default {
  name: 'companies',
  components: {ModalFilterAvanced, ModalNewCompany},
  data() {
    return {
      companies: [],
      controlHeight: { class: 'row mb-2 spacing-label-field' },
      schema: [],
      actions: [],
      rows: [],
      buttonSend: { icon: 'SearchIcon', color: 'warning', title: 'Filtrar' },
      fields: [
        { fieldType: 'FieldInput', type: 'text', name: 'company_name_code_id', label: 'Empresa', placeholder: this.$t('Ingrese nombre empresa') }
      ],
      formFilter: {},
      loading: {
        firstRender: true,
        table: true
      },
      show: {
        advancedFilter: false
      },
      is_mkp: false,
      filterFromMKP: false,
      keyTableRender: 0,
      keyFormRenderCompanies: 0,
      pagination: { page: 1, limit: 10, total: 1, loading: false },
      USER_ACTIONS: Object.freeze({
        EDIT: 'edit',
        BILLING: 'billing',
        CYBER_FORM: 'cyber_form',
        MODAL_FILTER: 'modalFilterAvancedCompanies',
        MODAL_COMPANIES: 'modalNewCompanies'
      }),
      firstCall: true
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      companiesListData: 'getCompaniesListData'
    })
  },
  watch: {
    companiesListData() {
      this.rows = this.companiesListData.rows.map(companyData => {
        return {
          ...companyData
        }
      })
      this.loading.table = false
    },
    mySession() {
      if (this.mySession.id && this.firstCall) this.setInitialData()
    },
    'pagination.page'() {
      this.filterFromMKP ? this.getCompanies(true) : this.getCompanies()
    }
  },
  mounted() {
    if (this.mySession.id) {
      this.setInitialData()
      this.firstCall = false
    }
  },
  methods: {
    // Creo los campos que en la tabla, botones de interacción y input de filtrado a mostrar
    setInitialData() {
      this.schema = [
        { label: 'Nombre', key: 'name1' },
        { label: 'Razón Social', key: 'name2', useSlot: true },
        { label: 'Código', key: 'code' },
        { label: 'Estado', key: 'status', useSlot: true},
        { label: 'Acciones', key: 'actions', class: ['text-center m-content'] },
        { label: 'Eliminar', key: 'deleteCompany', useSlot: true, class: ['text-center'] }
      ]
      this.actions = [
        { action: id => this.goTo(this.USER_ACTIONS.EDIT, id), icon: 'Edit2Icon', color: 'primary', text: this.$t('Editar') },
        { action: id => this.goTo(this.USER_ACTIONS.BILLING, id), icon: 'FileTextIcon', color: 'primary', text: this.$t('Ver facturas') },
        { action: id => this.goTo(this.USER_ACTIONS.CYBER_FORM, id), icon: 'ListIcon', color: 'primary', text: this.$t('Formulario cyber day') }
      ]
      //Vistas solo para adm
      if (['superadmin', 'admin'].includes(this.mySession.role)) {
        this.show.advancedFilter = true
        this.fields.unshift({ fieldType: 'FieldInput', type: 'text', name: 'mkp_name_code_id', label: this.$t('Marketplace'), placeholder: this.$t('Ingrese nombre markeplace') })
        const indexTr = this.schema.findIndex(schemaData => schemaData.key === 'status')
        this.schema.splice(
          indexTr,
          0,
          { label: 'Tipo', key: 'typeCompany', useSlot: true },
          { label: this.$t('Marketplaces'), key: 'marketplaces'}
        )
      }
      if (['marketplace'].includes(this.mySession.role)) {
        this.is_mkp = true
      }
      this.getCompanies()
      this.loading.firstRender = false
    },
    getCompanies(isMKP = false) {

      this.loading.table = true
      const queryParams = {
        calls: this.pagination.page,
        ...this.formFilter
      }
      if (isMKP) queryParams['mkp_name_code_id'] = this.mySession.organization?.id
      this.$store.dispatch('fetchService', { name: 'getCompaniesListData', queryParams})
    },
    /**
     * Mostrar o cerrar los distintos modales de este modulo
     * @param {string} name para saber cúal llamar
     */
    openModal(nameId) {
      this.$bvModal.show(nameId)
    },
    closeModal (nameId) {
      this.$bvModal.hide(nameId)
    },
    /**
     * Redireccionar hacia deferentes url de la plataforma
     * @param {string} name diferenciar hacia que lado va
     * @param {number} id solo recibe el id de la compañia 
     */
    goTo(name, id) {
      // Settear la url a donde va
      const pathname = name === this.USER_ACTIONS.EDIT ? 'companies' : name === this.USER_ACTIONS.BILLING ? 'billing' : 'cyber-form'
      window.parent.location.href = `${environment.platformUrl}/${pathname}/${id}`
    },
    // ir a la url de edicion de la compañia
    editCompanies(id) {
      this.$router.push({ name: 'edit-companies', params: { id }})
    },
    /**
     * Guardar los datos de filtro avanzado para que se pueda llamar "con paginacion"
     * @param {object} form Recibe el formulario del filtro con los datos a buscar
     */
    saveAdvancedFilterData(form) {
      if (form) {
        if (form.country) this.formFilter.country_id = form.country.id
        if (form.date_range) {
          this.formFilter.creation_date_from = this.$options.filters.moment(form.date_range.start, 'YYYY-MM-DD')
          this.formFilter.creation_date_to = this.$options.filters.moment(form.date_range.end, 'YYYY-MM-DD')
        }
        this.pagination.page > 1 ? this.pagination.page = 1 : this.getCompanies()
        this.closeModal(this.USER_ACTIONS.MODAL_FILTER)
      }
    },
    simpleFilter(isMKP) {
      if (isMKP) this.filterFromMKP = true
      this.pagination.page > 1 ? this.pagination.page = 1 : this.getCompanies(isMKP)
    },
    /**
     * Limpia todas las condiciones del filtrado
     */
    cleanCompaniesFilter(isFromFilter = true) {
      if (this.is_mkp) this.filterFromMKP = false
      this.formFilter = {}
      this.pagination.page > 1 ? this.pagination.page = 1 : this.getCompanies()
      if (isFromFilter) this.$refs.modalAvancedComp.cleanForm()
    },    
    /**
     * ELIMINAR compañia
     * @param {number} _id Recibe el id de la empresa que se va a eliminar pero primero pregunta para confirmar
     */
    confirmDeleteAlert(_id) {
      this.$yesno(this.$t('msg-pregunta-eliminar-compania'), () => this.deleteCompany(_id))
    },
    deleteCompany(id) {
      const queryParams = {}
      const params = {
        company_id: id
      }
      this.$store.dispatch('fetchService', { 
        name: 'deleteCompany', 
        queryParams, 
        params, 
        onSuccess: () => {
          this.$success(this.$t('msg-exito-eliminar-compania'))
          this.getCompanies()
        }
      })
      
    }
  }

}
</script>
<style lang='scss' scoped>
.manage-button {
  top: -3.5rem !important;
  right: 0rem !important;
  position: absolute !important;
  z-index: 9;
}
.delete_icon{
  color: red;
  cursor: pointer;
}
</style>